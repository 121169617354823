import { Markup } from 'interweave';
import React from 'react'
import { useLocation } from 'react-router-dom'
import './morenews.css';

function MoreNews() {
  const {state} = useLocation();

  return (
    <center>
        <div style={{margin:'5% auto'}}>
            <h1 style={{fontSize:'36px'}}>{state.title}</h1>
            <img src={state.img} alt='image not found!' style={{margin:'5% 10%', width:'20%'}}/>
            <center style={{margin:'2% 20%', textAlign:'justify', color:'black !important'}}>
                <Markup className='markup' content={state.content} />
            </center>
        </div>
    </center>
  )
}

export default MoreNews