import React from 'react'
import cli1 from './images/Picture1.png';
import cli2 from './images/Picture2.png';
import cli3 from './images/Picture3.png';
import cli4 from './images/Picture4.png';
import cli5 from './images/Picture5.png';
import cli6 from './images/Picture6.png';
import cli7 from './images/Picture7.png';
import cli8 from './images/Picture8.png';
import './ourclients.css';

const OurClients = () => {
  return (
    <section class="section category" aria-label="category">
        <div class="container">

         
          <p class="section-subtitle">Some of Our</p>

          <h2 class="h2 section-title has-underline">
            Clients
          </h2>

          <ul style={{display:'flex', flexDirection:'row',flexWrap:'wrap'}}>

            
                  <img src={cli1} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
         
                  <img src={cli2} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
          
                  <img src={cli3} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
          
                  <img src={cli4} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
           
                  <img src={cli5} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
            
                  <img src={cli6} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
           
                  <img src={cli7} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
            
                  <img src={cli8} style={{width:'15%', alignItem:'center', margin:'auto'}} loading="lazy" alt="Cat Food"
                    class="img-cover"/>
            
            
          </ul>

        </div>
      </section>
  )
}

export default OurClients