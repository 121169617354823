import React from "react";
import "./about.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faBusinessTime,
  faPeopleGroup,
  faShop,
} from "@fortawesome/free-solid-svg-icons";
import passion from "./images/passion.png";
import humanity from "./images/humanity.png";
import accountability from "./images/accoutability.png";
import team from "./images/team work.png";

const About = () => {
  return (
    <section className="section about" id="about" aria-label="about">
      <div className="container">
        <div className="grid-list">
          <li className="about-content">
            <p className="section-subtitle">About Us</p>
            <h2 className="h2 section-title text-center">Who are we?</h2>
            <div className="section-text">
              Path Consulting PLC is a professional services firm with a core
              focus on management consulting and training services based in
              Addis Ababa, Ethiopia. Path brings a wide range of consulting
              solutions: Human Capital, Business Strategy, Learning Solutions,
              and Marketing and Sales.
            </div>

            <h2 className="h2 section-title text-center">Core Values</h2>
            <div style={{display:'flex', flexDirection:'row'}} className="card-text">
              <img src={passion} style={{width:'30px', height:'30px', marginRight:'15px'}} />
              Passion - We are passionate about what we do and strive for
              excellence in our work.
            </div>
            <div style={{display:'flex', flexDirection:'row'}} className="card-text">
              <img src={accountability} style={{width:'30px', height:'30px', marginRight:'15px'}} />
              Accountability - Taking responsibility for our actions and
              ensuring transparency and integrity in all we do.
            </div>
            <div style={{display:'flex', flexDirection:'row'}} className="card-text">
              <img src={team} style={{width:'30px', height:'30px', marginRight:'15px'}} />
              Teamwork - Fostering collaboration, communication, and mutual
              support to achieve shared goals.
            </div>
            <div style={{display:'flex', flexDirection:'row'}} className="card-text">
              <img src={humanity} style={{width:'30px', height:'30px', marginRight:'15px'}} />
              Humanity - We treat others with respect, empathy, and kindness.
            </div>
          </li>

          <li>
            <div className="about-card has-before has-after">
              <div className="card-icon">
                <img
                  src="./assets/images/about-4.svg"
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Vision"
                />
              </div>

              <h3 className="h3 card-title">Vision</h3>

              <p className="card-text">
                To be one of the most sought-after management consulting firm in
                Ethiopia.
              </p>

              <a href="#" className="btn-link">
                <span className="span">Read more</span>

                <ion-icon
                  name="chevron-forward-outline"
                  aria-hidden="true"
                ></ion-icon>
              </a>
            </div>
          </li>

          <li>
            <div className="about-card has-before has-after">
              <div className="card-icon">
                <img
                  src="./assets/images/about-5.svg"
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Mission"
                />
              </div>

              <h3 className="h3 card-title">Mission</h3>

              <p className="card-text">
                We strive to provide innovative and strategic management
                consulting solutions that drive sustainable growth, optimize
                performance, and enhance organizational effectiveness.
              </p>

              <a href="#" className="">
                <span className="span" style={{ color: "black" }}>
                  Read more
                </span>

                <ion-icon
                  name="chevron-forward-outline"
                  aria-hidden="true"
                ></ion-icon>
              </a>
            </div>
          </li>
        </div>
      </div>
    </section>
  );
};

export default About;
