import React from 'react'
import Header from './Header'
import Hero from './Hero'
import Services from './Services'
import News from './News'
import './home.css'
import About from './About'
import Contact from './Contact'
import Footer from './Footer'
import Videos from './Videos'
import OurClients from './OurClients'

const Home = () => {
  return (
    <div>
        <Header path='home'/>
        <Hero/>
        <About/>
        <News/>
        <Services/>
        <Videos/>
        <OurClients/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Home