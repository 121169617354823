import React, { useRef } from "react";
import "./contact.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import cont_side from "./images/cont_side.png";
import emailjs from "@emailjs/browser";
import tel from "./images/download.png";

const Contact = () => {
  const formRef = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_wzd3jsg",
        "template_oqsn9wf",
        formRef.current,
        "eb0-bpbEyKKQPBsnw"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    formRef.current[0].value = "";
    formRef.current[1].value = "";
    formRef.current[2].value = "";
    formRef.current[3].value = "";
  };

  return (
    <section class="section contact" id="contact" aria-label="contact">
      <div class="container">
        <p class="section-subtitle">Contact Us</p>

        <h2 class="h2 section-title has-underline">Ask us anything</h2>

        <div class="wrapper">
          <form
            action=""
            class="contact-form"
            ref={formRef}
            onSubmit={sendEmail}
          >
            <input
              type="text"
              name="user_name"
              style={{ color: "#aba698" }}
              placeholder="Your Name"
              required
              class="input-field"
            />

            <input
              type="email"
              name="user_email"
              placeholder="Your Email"
              style={{ color: "#aba698" }}
              required
              class="input-field"
            />

            <input
              type="text"
              name="user_phone"
              placeholder="Subject"
              style={{ color: "#aba698" }}
              required
              class="input-field"
            />

            <textarea
              name="message"
              style={{ color: "#aba698" }}
              placeholder="Your Message"
              class="input-field"
            ></textarea>
            <ceter>
              <button
                type="submit"
                class=""
                style={{
                  color: "white",
                  backgroundColor: "#AA9563",
                  padding: "2% 5%",
                  justifyContent: "center",
                }}
              >
                Send Now
              </button>
            </ceter>
          </form>

          <ul class="contact-card">
            <li>
              <p class="card-title">Address:</p>

              <address class="address" style={{ color: "#aa9563" }}>
                Addis Ababa <br />
                Ethiopia
              </address>
            </li>

            <li>
              <p class="card-title">Phone:</p>

              <a href="tel:1234567890" class="card-link">
                +251911019903
              </a>
            </li>

            <li>
              <p class="card-title">Email:</p>

              <a href="mailto:support@support.com" class="card-link">
                support@pathconsultinget.com
              </a>
            </li>

            <li>
              <p class="social-list-title h3">Connect book socials</p>

              <ul class="social-list">
              <li>
              <a
                href="https://youtube.com/@PathLearningEt?si=2ilPML_om2oKjz7s"
                target="_blank"
                class="social-link"
              >
                <ion-icon name="logo-youtube"></ion-icon>
              </a>
            </li>
                <li style={{width:'20%'}}>
                  <a
                    href="https://t.me/pathconsult"
                    target="_blank"
                    class="social-link"
                  >
                    <center>
                      <img src={tel} alt="" style={{ width: "60%" }} />
                    </center>
                  </a>
                </li>

                
                <li>
                  <a href="#" class="social-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>

                

              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Contact;

