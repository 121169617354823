import React from 'react'
import MoreNews from '../MoreNews';
import Footer from '../Footer';
import Topbar from '../Topbar';
import Header from '../Header';
import Banner from '../Banner';
import { useLocation } from 'react-router-dom';
import p3 from '../images/p3.png';
const Details = () => {
    const {state} = useLocation();

  return (
    <div>
        <Topbar/>
        <Header path='news'/>
        <Banner path={'Blog'} img={p3}/>
        <MoreNews/>
        <Footer/>
    </div>
  )
}

export default Details