import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import "./header.css";
import logo from "./images/Amino101-04.png";
import { Link } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { ListItem } from "flowbite-react";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0, 1",
  justifyContent: "flex-end",
}));

const Header = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      // Set the state based on the scroll position
      setIsScrolled(window.scrollY > 0);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Topbar />
      <header data-header className={`header ${isScrolled ? "active" : ""}`}>
        <div className="container">
          <Link to="/" className="logo">
            <img src={logo} width="160" height="50" alt="Grilli - Home" />
          </Link>

          <nav className="navbar" data-navbar>
            <button
              className="close-btn"
              aria-label="close Service"
              data-nav-toggler
            >
              <IonIcon icon={closeCircleOutline} size="large"></IonIcon>
            </button>

            <Link to="/" className="logo">
              <img
                src="./assets/images/logo.svg"
                width="160"
                height="50"
                alt="Grilli - Home"
              />
            </Link>

            <ul className="navbar-list">
              <li className="navbar-item">
                <Link
                  to="/"
                  className={`navbar-link  ${
                    props.path == "home" ? "active" : ""
                  }`}
                >
                  <div className="separator"></div>

                  <span className="span">Home</span>
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/about"
                  className={`navbar-link  ${
                    props.path == "about" ? "active" : ""
                  }`}
                >
                  <div className="separator"></div>

                  <span className="span">About Us</span>
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/service"
                  className={`navbar-link  ${
                    props.path == "service" ? "active" : ""
                  }`}
                >
                  <div className="separator"></div>

                  <span className="span">Services</span>
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/news"
                  className={`navbar-link  ${
                    props.path == "news" ? "active" : ""
                  }`}
                >
                  <div className="separator"></div>

                  <span className="span">News & Updates</span>
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/videos"
                  className={`navbar-link  ${
                    props.path == "videos" ? "active" : ""
                  }`}
                >
                  <div className="separator"></div>

                  <span className="span">Videos</span>
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/contact"
                  className={`navbar-link  ${
                    props.path == "contact" ? "active" : ""
                  }`}
                >
                  <div className="separator"></div>

                  <span className="span">Contact Us</span>
                </Link>
              </li>
            </ul>
          </nav>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
              overflowY:'scroll'
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <div style={{ left: "0" }}>
                <img src={logo} width="160" height="50" alt="Grilli - Home" />
              </div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ion-icon name="arrow-dropright"></ion-icon>
                ) : (
                  <ion-icon name="arrow-dropleft"></ion-icon>
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem disablePadding>
                <Link to="/">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="home"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"Home"} />
                  </ListItemButton>
                </Link>
                <Link to="/about">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="information-circle-outline"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"About Us"} />
                  </ListItemButton>
                </Link>
                <Link to="/service">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="cog"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"Services"} />
                  </ListItemButton>
                </Link>
                <Link to="/news">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="megaphone"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"News & Updates"} />
                  </ListItemButton>
                </Link>
                <Link to="/videos">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="videocam"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"Videos"} />
                  </ListItemButton>
                </Link>
                <Link to="/contact">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="call"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"Contact Us"} />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
            <Divider />
            <div className="text-center" style={{ marginTop: "10%" }}>
              <h1 style={{ fontSize: "30px" }} className="body-1">
                Visit Us
              </h1>

              <address className="body-4">Addis Ababa, Ethiopia</address>

              <a style={{ color: "black" }}>Open: 8am - 5pm</a>

              <h1 style={{ marginTop: "10px" }} className="body-1">
                Contact Us
              </h1>

              <a
                href="tel:+25197291772"
                className=" contact-number hover-underline"
              >
                +25197291772
              </a>
              <a
                href="mailto:info@pathconsulting.com"
                className="body-4 sidebar-link"
              >
                info@pathconsulting.com
              </a>
            </div>
          </Drawer>
          <button
            className="nav-open-btn"
            aria-label="open Service"
            data-nav-toggler
            onClick={handleDrawerOpen}
            style={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>

          <div
            // className={`header-overlay  ${ac == true ? "active" : ""}`}
            className="header-overlay"
            data-nav-toggler
            data-overlay
          ></div>
        </div>
      </header>
    </div>
  );
};

export default Header;
