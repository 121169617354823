import React from "react";
import "./banner.css";
import banner from "./images/about-banner.jpg";

const Banner = (props) => {
  return (
    <section className="banner text-center " aria-label="home" id="home">
      <ul className="banner-slider" data-banner-slider>
        <li className="slider-item active" data-banner-slider-item>
          <div className="slider-bg" style={{ backgroundColor: "#15504e" }}>
            <img
              src={props.img}
              alt=""
              style={{ width: "100vw", height:'100%' }}
            />
          </div>

          <h1 className="display-1 banner-title slider-reveal">{props.path}</h1>
        </li>
      </ul>
    </section>
  );
};

export default Banner;
