import { React, useEffect } from "react";
import "./hero.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";
import Hero_1 from "./images/hero-slider-1.jpg";
import Hero_2 from "./images/hero-slider-2.jpg";
import Hero_3 from "./images/hero-slider-3.jpg";
import { Link } from "react-router-dom";

const Hero = () => {
  useEffect(() => {
    const heroSlider = document.querySelector("[data-hero-slider]");
    const heroSliderItems = document.querySelectorAll(
      "[data-hero-slider-item]"
    );
    const heroSliderPrevBtn = document.querySelector("[data-prev-btn]");
    const heroSliderNextBtn = document.querySelector("[data-next-btn]");

    let currentSlidePos = 0;
    let lastActiveSliderItem = heroSliderItems[0];

    const updateSliderPos = function () {
      lastActiveSliderItem.classList.remove("active");
      heroSliderItems[currentSlidePos].classList.add("active");
      lastActiveSliderItem = heroSliderItems[currentSlidePos];
    };

    const slideNext = function () {
      if (currentSlidePos >= heroSliderItems.length - 1) {
        currentSlidePos = 0;
      } else {
        currentSlidePos++;
      }

      updateSliderPos();
    };

    heroSliderNextBtn.addEventListener("click", slideNext);

    const slidePrev = function () {
      if (currentSlidePos <= 0) {
        currentSlidePos = heroSliderItems.length - 1;
      } else {
        currentSlidePos--;
      }

      updateSliderPos();
    };

    heroSliderPrevBtn.addEventListener("click", slidePrev);

    /**
     * auto slide
     */

    let autoSlideInterval;

    const autoSlide = function () {
      autoSlideInterval = setInterval(function () {
        slideNext();
      }, 7000);
    };

    [heroSliderNextBtn, heroSliderPrevBtn].forEach((btn) => {
      btn.addEventListener("mouseover", function () {
        clearInterval(autoSlideInterval);
      });

      btn.addEventListener("mouseout", autoSlide);
    });

    window.addEventListener("load", autoSlide);

    // Cleanup the event listeners when the component unmounts
    return () => {
      [heroSliderNextBtn, heroSliderPrevBtn].forEach((btn) => {
        btn.removeEventListener("mouseover", function () {
          clearInterval(autoSlideInterval);
        });

        btn.removeEventListener("mouseout", autoSlide);
      });

      window.removeEventListener("load", autoSlide);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div>
      <section className="hero text-center" aria-label="home" id="home">
        <ul className="hero-slider" data-hero-slider>
          <li className="slider-item active" data-hero-slider-item>
            <div className="slider-bg">
              <img
                src={Hero_1}
                width="1880"
                height="950"
                alt=""
                className="img-cover"
              />
            </div>
            <div className="cover">
              <p className="label-2 section-subtitle slider-reveal">
                Best Service
              </p>

              <h1 className="display-1 hero-title slider-reveal">
                Building the foundation for <br />a sustainable future with
                every step!
              </h1>

              <p className="body-2 hero-text slider-reveal">
                Charting a course towards sustainable growth, one innovation at
                a time!
              </p>

              <Link to="/service" className="btn btn-primary slider-reveal">
                <span className="text text-1">View Our Services</span>

                <span className="text text-2" aria-hidden="true">
                  View Our Services
                </span>
              </Link>
            </div>
          </li>

          <li className="slider-item" data-hero-slider-item>
            <div className="slider-bg">
              <img
                src={Hero_2}
                width="1880"
                height="950"
                alt=""
                className="img-cover"
              />
            </div>
            <div className="cover">
              <p className="label-2 section-subtitle slider-reveal">
                Delightful Experience
              </p>

              <h1 className="display-1 hero-title slider-reveal">
                Paving The Path To <br />
                Sustainable Growth!!
              </h1>

              <p className="body-2 hero-text slider-reveal">
                Forging a trail towards a future of sustainable progress!
              </p>

              <Link to="/service" className="btn btn-primary slider-reveal">
                <span className="text text-1">View Our Services</span>

                <span className="text text-2" aria-hidden="true">
                  View Our Services
                </span>
              </Link>
            </div>
          </li>

          <li className="slider-item" data-hero-slider-item>
            <div className="slider-bg">
              <img
                src={Hero_3}
                width="1880"
                height="950"
                alt=""
                className="img-cover"
              />
            </div>
            <div className="cover">
              <p className="label-2 section-subtitle slider-reveal">
                Impactful Journey
              </p>

              <h1 className="display-1 hero-title slider-reveal">
                Thriving Through Sustainable Growth, <br />
                For a better world.
              </h1>

              <p className="body-2 hero-text slider-reveal">
                Balancing progress and preservation through sustainable growth.
              </p>

              <Link to="/service" className="btn btn-primary slider-reveal">
                <span className="text text-1">View Our Services</span>

                <span className="text text-2" aria-hidden="true">
                  View Our Services
                </span>
              </Link>
            </div>
          </li>
        </ul>

        <button
          className="slider-btn prev"
          aria-label="slide to previous"
          data-prev-btn
        >
          <IonIcon icon={caretBackOutline} size="large"></IonIcon>
        </button>

        <button
          className="slider-btn next"
          aria-label="slide to next"
          data-next-btn
        >
          <IonIcon icon={caretForwardOutline} size="large"></IonIcon>
        </button>
      </section>
    </div>
  );
};

export default Hero;
