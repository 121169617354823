import React from "react";
import "./footer.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import { call } from "ionicons/icons";
import { location, time, mail } from "ionicons/icons";
import logo from "./images/Amino101-04.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="footer has-bg-image">
        <div className="container">
          <div className="footer-top">
            <div className="footer-brand">
              <a href="#" className="logo">
                <img src={logo} alt="" />
              </a>

              <form action="" className="input-wrapper">
                <input
                  type="email"
                  name="email_address"
                  placeholder="Enter Your Email"
                  required
                  className="email-field"
                />

                <button type="submit" className="btn">
                  <span className="span">Subscribe Now</span>

                  <IonIcon icon={caretForwardOutline} size="large"></IonIcon>
                </button>
              </form>
            </div>

            <div className="footer-link-box">
              <ul className="footer-list">
                <li>
                  <p className="footer-list-title">Quick Links</p>
                </li>

                <li>
                  <Link to="/about" className="footer-link has-before">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/service" className="footer-link has-before">
                    Our Services
                  </Link>
                </li>

                <li>
                  <Link to="/news" className="footer-link has-before">
                    News & Updates
                  </Link>
                </li>
                <li>
                  <Link to="/videos" className="footer-link has-before">
                    Videos
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="footer-link has-before">
                    Contact Us
                  </Link>
                </li>
              </ul>

              <ul className="footer-list">
                <li>
                  <p className="footer-list-title">Services</p>
                </li>

                <li>
                  <Link to="/service" className="footer-link has-before">
                    Learning Solutions
                  </Link>
                </li>

                <li>
                  <Link to="/service" className="footer-link has-before">
                    Human Capital
                  </Link>
                </li>

                <li>
                  <Link to="/service" className="footer-link has-before">
                    Business Strategy
                  </Link>
                </li>

                <li>
                  <Link to="/service" className="footer-link has-before">
                    Marketing & Sales
                  </Link>
                </li>
              </ul>

              <ul className="footer-list">
                <li>
                  <p className="footer-list-title">Contact Us</p>
                </li>

                <li className="footer-list-item">
                  <IonIcon
                    icon={location}
                    size="large"
                    style={{ color: "white" }}
                  ></IonIcon>

                  <address className="contact-link">
                    Addis Ababa, Ethiopia
                  </address>
                </li>

                <li className="footer-list-item">
                  <IonIcon
                    icon={call}
                    size="large"
                    style={{ color: "white" }}
                  ></IonIcon>

                  <a href="tel:+0123456789" className="contact-link">
                    +251911019903
                  </a>
                </li>

                <li className="footer-list-item">
                  <IonIcon
                    icon={time}
                    size="large"
                    style={{ color: "white" }}
                  ></IonIcon>

                  <span className="contact-link">Mon - Sat, 08:00 am - 5:00 pm</span>
                </li>

                <li className="footer-list-item">
                  <IonIcon
                    icon={mail}
                    size="large"
                    style={{ color: "white" }}
                  ></IonIcon>

                  <a href="mailto:support@gmail.com" className="contact-link">
                    support@pathconsultinget.com
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-copyright">
            <p className="copyright">
              &copy; 2024{" "}
              <a
                href="#"
                className="copyright-link"
                style={{ marginRight: "1%" }}
              >
                Path Consulting.
              </a>
              All Rights Reserved.
            </p>
          </div>
          <div className="footer-bottom">
            <p className="copyright">
              Developed by
              <a
                href="https://earlystarttech.com"
                target="_blank"
                className="copyright-link"
                style={{ marginLeft: "1%" }}
              >
                Early Start IT Solutions
              </a>
            </p>
          </div>
        </div>
      </footer>

      <a
        href="#top"
        class="back-top-btn"
        aria-label="back to top"
        data-back-top-btn
      >
        <ion-icon name="chevron-up" aria-hidden="true"></ion-icon>
      </a>
      <script src="./"></script>
    </div>
  );
};

export default Footer;
