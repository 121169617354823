import React from 'react';
import Topbar from '../Topbar';
import Header from '../Header';
import Footer from '../Footer';
import Banner from '../Banner';
import News_Com from '../News';
import news from '../images/news 2.jpg';
const News = () => {
  return (
    <div>
        <Topbar/>
        <Header path='news'/>
        <Banner path='News & Updates' img={news}/>
        <News_Com/>
        <Footer/>       
    </div>
  )
}

export default News