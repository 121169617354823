import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import About from "./pages/About";
import Service from "./pages/Service";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Videos from "./pages/Videos";
import MoreNews from './MoreNews'
import Details from "./pages/Details";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/news" element={<News />} />
      <Route path="/service" element={<Service />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/detail" element={<Details />} />
    </Routes>
  );
}

export default App;
