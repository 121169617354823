import React from 'react';
import Topbar from '../Topbar';
import Header from '../Header';
import Footer from '../Footer';
import Banner from '../Banner';
import Videos_Com from '../Videos';
import video from '../images/video.jpg';
const Videos = () => {
  return (
    <div>
        <Topbar/>
        <Header path='videos'/>
        <Banner path='Videos' img = {video}/>
        <Videos_Com/>
        <Footer/>       
    </div>
  )
}

export default Videos