import React from 'react';
import Topbar from '../Topbar';
import Header from '../Header';
import Footer from '../Footer';
import Banner from '../Banner';
import About_Com from '../About';
import about from '../images/about us.jpg';

// import p1 from '../images/p1.png';
// import p1 from '../images/p1.png';

const About = () => {
  return (
    <div>
        <Topbar/>
        <Header path='about'/>
        <Banner path='About Us' img={about}/>
        <About_Com/>
        <Footer/>       
    </div>
  )
}

export default About