import React from 'react';
import Topbar from '../Topbar';
import Header from '../Header';
import Footer from '../Footer';
import Banner from '../Banner';
import Contact_Com from '../Contact';
import contact from '../images/contact us 1.jpg';

const Contact = () => {
  return (
    <div>
        <Topbar/>
        <Header path='contact' />
        <Banner path='Contact Us' img={contact}/>
        <Contact_Com/>
        <Footer/>       
    </div>
  )
}

export default Contact