import React, { useEffect, useState } from "react";
import "./news.css";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import { db, storage } from "./firebase";
import { Button, Card, Modal } from "flowbite-react";


const News = () => {

  const [news, setNews] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "news"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setNews(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);

  const navigate = useNavigate();

  const handleDetail = (row) => {
    navigate("/detail", { state: { ...row } });
  };


  return (
    <section className="section blog" aria-label="blog" id="blog">
      <div className="container">
        <p className="section-subtitle">Blog</p>

        <h2 className="h2 section-title">Recent news & events</h2>

        <p className="section-text">
          
        </p>


        

        <ul className="blog-list grid-list">
        
        {news.map((n)=> {
         return (
           <li>
             <Card className="video-card">
               <figure className="card-banner img-holder">
                 <img src={n.img} className="player" />
               </figure>
               <div className="card-content">
                 <h3 className="h3 card-title" style={{ height: "100%" }}>
                   {n.title}
                 </h3>

                 <p
                   className="card-text"
                   style={{ overflowY: "scroll", height: "10vh", marginBottom:'5%' }}
                 >
                   {n.snippet}
                 </p>

                 <button
                   onClick={(e) => {
                     handleDetail(n);
                   }}
                   target="_blank"
                   className="video_button"
                   style={{}}
                 >
                   Read More
                 </button>
               </div>
             </Card>
           </li>
         );
         })}

          
        </ul>
      </div>
    </section>
  );
};

export default News;
