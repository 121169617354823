import React from 'react';
import Topbar from '../Topbar';
import Header from '../Header';
import Footer from '../Footer';
import Banner from '../Banner';
import "../services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBusinessTime, faPeopleGroup, faShop } from "@fortawesome/free-solid-svg-icons";
import service from '../images/services 1.png';

const Service = () => {
  return (
    <div>
        <Topbar/>
        <Header path='service'/>
        <Banner path='Our Services' img={service}/>
        <section className=" service" id="services" aria-label="services">
      <div className="container">
        <p className="section-subtitle">Our Services</p>
        <h2 className="h2 section-title text-center">Service We Provide</h2>

        

        <ul className="grid-list">
          <li>
            <div className="page-service-card">
              <div className="card-icon">
                <FontAwesomeIcon icon={faBook} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Learning Solutions
                </a>
              </h3>

              <p className="card-text">
                Learning Management System Design and L&D Strategy Design. Customer-Focused Trainings, Leadership and Management Trainings, Organizational and Business Skills Trainings, and Sector- Specific Technical kills Trainings. 
              </p>

            </div>
          </li>

          <li>
            <div className="page-service-card">
              <div className="card-icon">
                <FontAwesomeIcon icon={faPeopleGroup} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Human Capital
                </a>
              </h3>

              <p className="card-text">
                Organizational Design, Organizational Structure, Job Evaluation and Grading, Salary and Compensation Plan, Performance Management System.
              </p>

            </div>
          </li>

          <li>
            <div className="page-service-card">
              <div className="card-icon">
                <FontAwesomeIcon icon={faBusinessTime} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Business Strategy
                </a>
              </h3>

              <p className="card-text">
              Strategy Formulation and Implementation Support, Business Model Transformation.

              </p>

            </div>
          </li>

          <li>
            <div className="page-service-card">
              <div className="card-icon">
                <FontAwesomeIcon icon={faShop} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Marketing & Sales
                </a>
              </h3>

              <p className="card-text">
              Effective Marketing Strategies, Sales Optimization, Brand Positioning, Customer Experience Management, and Customer Journey Mapping.

              </p>

            </div>
          </li>

          
        </ul>
      </div>
    </section>
        <Footer/>       
    </div>
  )
}

export default Service