import React from "react";
import "./services.css";
import { IonIcon } from '@ionic/react';
import {caretForwardOutline} from 'ionicons/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBusinessTime, faPeopleGroup, faShop } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


const Services = () => {
  return (
    <section className=" service" id="services" aria-label="services">
      <div className="container">
        <p className="section-subtitle">Our Services</p>
        <h2 className="h2 section-title text-center">Service We Provide</h2>
        <ul className="grid-list">
          <li>
            <div className="service-card">
              <div className="card-icon">
                <FontAwesomeIcon icon={faBook} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Learning Solutions
                </a>
              </h3>


              <Link to='/service' className="card-btn" aria-label="more">
                <IonIcon icon={caretForwardOutline} size="large"></IonIcon>
              </Link>
            </div>
          </li>

          <li>
            <div className="service-card">
              <div className="card-icon">
              <FontAwesomeIcon icon={faPeopleGroup} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Human Capital
                </a>
              </h3>

              <Link to='/service' href="#" className="card-btn" aria-label="more">
                <IonIcon icon={caretForwardOutline} size="large"></IonIcon>
              </Link>
            </div>
          </li>

          <li>
            <div className="service-card">
              <div className="card-icon">
                <FontAwesomeIcon icon={faBusinessTime} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Business Strategy
                </a>
              </h3>

              <Link to='/service' href="#" className="card-btn" aria-label="more">
                <IonIcon icon={caretForwardOutline} size="large"></IonIcon>
              </Link>
            </div>
          </li>

          <li>
            <div className="service-card">
              <div className="card-icon">
                <FontAwesomeIcon icon={faShop} color="#15504e" size="lg" />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Marketing & Sales
                </a>
              </h3>
              <Link to='/service' className="card-btn" aria-label="more">
                <IonIcon icon={caretForwardOutline} size="large"></IonIcon>
              </Link>
            </div>
          </li>

        </ul>
      </div>
    </section>
  );
};

export default Services;
